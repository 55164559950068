/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
import React, { useState, useEffect, useRef } from "react";
// import "../styles/win-bloggers.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const WinBloggers = () => {
	const swiperRef1 = useRef(null);
	const swiperRef = useRef(null);
	const [range, setRange] = useState(500);

	const [data, setData] = useState({});

	const [device, setDevice] = useState(false);
	const [timeVersion, setTimeVersion] = useState(0);

	useEffect(() => {
		window.addEventListener("message", event => {
			if (event.origin !== "https://panelhunt.io") {
				return; // Ігноруємо повідомлення від невідомого джерела
			}

			// У випадку локального тестування дозволяємо всі джерела
			const receivedData = event.data;

			console.log("Отримані дані:", receivedData);
			setData(receivedData);
			setTimeVersion(new Date().getTime());
		});
	}, []);

	return (
		<>
			<div className="body">
				<header className="header">
					<picture className="header__logo">
						<source
							media="(min-width: 768px)"
							srcSet={
								!!data?.appIcon
									? `https://api.raulnk.com/${data?.id}/images/icons/${data?.currentLang}/${data?.appIcon}?v=${timeVersion}`
									: "/win-bloggers/images/site/1win-logo.svg"
							}
							width="100"
							height="39"
							type="image/svg+xml"
						/>
						<img
							src={
								!!data?.appIcon
									? `https://api.raulnk.com/${data?.id}/images/icons/${data?.currentLang}/${data?.appIcon}?v=${timeVersion}`
									: "/win-bloggers/images/site/1win-logo.svg"
							}
							alt="1win logo"
							width="50"
							height="20"
						/>
					</picture>
					<nav className="nav-menu header__nav-menu">
						<ul className="list nav-menu__list">
							<li className="nav-menu__item">
								<a
									href="#reviews"
									className="nav-menu__link"
								>
									{data?.inscriptions?.menu_1}
								</a>
							</li>
							<li className="nav-menu__item">
								<a
									href="#education"
									className="nav-menu__link"
								>
									{data?.inscriptions?.menu_2}
								</a>
							</li>
							<li className="nav-menu__item">
								<a
									href="#contacts"
									className="nav-menu__link"
								>
									{data?.inscriptions?.menu_3}
								</a>
							</li>
						</ul>
					</nav>
					<div
						className="select-lang"
						data-select-lang=""
					>
						<button
							style={{ opacity: 0 }}
							className="select-lang__header"
							data-select-lang-trigger=""
						>
							<svg
								className="select-lang__flag"
								aria-hidden="true"
								width="16"
								height="16"
							>
								<use
									data-1win-form="select-flag"
									xlinkHref="/win-bloggers/images/site/sprite-flags.svg#eng"
									data-select-lang-flag=""
								></use>
							</svg>
							<span
								className="select-lang__text"
								data-select-lang-country-key=""
							>
								en
							</span>
							<svg
								className="select-lang__arrow-icon"
								aria-hidden="true"
								width="18"
								height="18"
								data-select-lang-arrow=""
							>
								<use xlinkHref="/win-bloggers/images/site/sprite-form.svg#arrow"></use>
							</svg>
						</button>
					</div>
				</header>
				<main>
					<section className="section-hero">
						<h1 className="title title_theme_orange section-hero__title">
							<span className="title_size_lg section-hero__title-top">
								{data?.inscriptions?.title.split(" ")[0] || "Recommendations"}
							</span>
							<span className="title_size_xl section-hero__title-bottom">
								{data?.inscriptions?.title.split(" ").splice(1).join(" ") || "from bloggers"}
							</span>
						</h1>
						<div className="promo section-hero__promo">
							<div className="profile-block promo__profile-block">
								<div className="profile-block__top">
									<picture className="profile-block__picture">
										<img
											className="profile-block__avatar"
											src={
												`https://api.raulnk.com/${data?.id}/images/instagram/avatar/${data?.currentLang}/${data?.instagram?.avatar}?v=${timeVersion}` ||
												"https://cdn35998572.blazingcdn.net/projects/hin-project/img/section-hero/full-photo.webp"
											}
											alt="Avatar of Leslie Alexander"
										/>
									</picture>
									<p className="profile-block__info">
										<span className="profile-block__name">
											{data?.instagram?.name || "Leslie Alexander"}
										</span>
										<span className="profile-block__city">
											{data?.instagram?.country || "San Juan"}
										</span>
									</p>
								</div>

								<div className="profile-block__middle">
									<picture className="profile-block__picture">
										<img
											className="profile-block__full-photo"
											src={
												`https://api.raulnk.com/${data?.id}/images/instagram/avatar/${data?.currentLang}/${data?.instagram?.avatar}?v=${timeVersion}` ||
												"https://cdn35998572.blazingcdn.net/projects/hin-project/img/section-hero/full-photo.webp"
											}
											width="141"
											height="138"
											alt="Photo of Leslie Alexander"
										/>
									</picture>
								</div>
								<div className="profile-block__bottom">
									<ul className="list profile-block__buttons">
										<li className="profile-block__buttons-item">
											<svg
												className="profile-block__icon"
												aria-hidden="true"
												width="8"
												height="7"
											>
												<use xlinkHref="/win-bloggers/images/site/sprite-hero.svg#heart"></use>
											</svg>
										</li>
										<li className="profile-block__buttons-item">
											<svg
												className="profile-block__icon"
												aria-hidden="true"
												width="8"
												height="7"
											>
												<use xlinkHref="/win-bloggers/images/site/sprite-hero.svg#comment"></use>
											</svg>
										</li>
										<li className="profile-block__buttons-item">
											<svg
												className="profile-block__icon"
												aria-hidden="true"
												width="8"
												height="7"
											>
												<use xlinkHref="/win-bloggers/images/site/sprite-hero.svg#share"></use>
											</svg>
										</li>
									</ul>

									<svg
										className="profile-block__icon"
										aria-hidden="true"
										width="6.5"
										height="8.5"
									>
										<use xlinkHref="/win-bloggers/images/site/sprite-hero.svg#add"></use>
									</svg>
								</div>
							</div>
							<div className="promocode-block promo__promocode-block">
								<p className="promocode-block__paragraph">{data?.inscriptions?.promocode}</p>
								<p
									className="promocode-block__promocode"
									id="promocode"
								>
									{data?.instagram?.promocode || "INDI500"}
								</p>
								<ul className="list promocode-block__list">
									<li className="promocode-block__item">
										<button
											className="button button_theme_gray button_size_default promocode-block__copy-button"
											id="copy-button"
										>
											<svg
												aria-hidden="true"
												width="11.5"
												height="11.5"
											>
												<use xlinkHref="/win-bloggers/images/site/sprite-hero.svg#copy-button"></use>
											</svg>
											{/* <span
												id="hidden-result-text"
												hidden=""
											>
												Copied
											</span> */}
											<span id="copy-button-text">{data?.inscriptions?.copy || "Copy"}</span>
										</button>
									</li>
									<li className="promocode-block__item">
										<a
											href="/application/apk/1win.apk"
											className="button button_theme_default button_size_default promocode-block__play-button"
										>
											{data?.inscriptions?.play || "Play"}
										</a>
									</li>
								</ul>
							</div>
						</div>
					</section>
					<section className="calculator">
						<div className="container">
							<h2 className="calculator__title title title_theme_light title_size_xxs">
								<p className="calculator__text">
									{data?.inscriptions?.deposit_title || "Make a deposit and we'll multiply it by 5"}
								</p>
							</h2>
							<ul className="calculator__list list">
								<li className="calculator__item">
									<p className="calculator__text calculator__text_left calculator__text_size_xs calculator__text_opacity">
										{data?.inscriptions?.deposit || "Deposited"}
									</p>
									<p className="calculator__text calculator__text_size_md">
										{data?.inscriptions?.currency || "₹"}{" "}
										<span className="amount-default">{range}</span>
									</p>
								</li>
								<li className="calculator__item calculator__item_left">
									<p className="calculator__text calculator__text_left calculator__text_size_xs calculator__text_opacity">
										{data?.inscriptions?.profit || "Profit"}
									</p>
									<p className="calculator__text calculator__text_size_md">
										{data?.inscriptions?.currency || "₹"}{" "}
										<span className="amount-enlarged">{range * 5}</span>
									</p>
								</li>
							</ul>
							<label htmlFor="myRange">
								<p style={{ display: "none" }}>Make a deposit and we'll multiply it by 5</p>
								<input
									id="myRange"
									value={range}
									step="50"
									type="range"
									min="500"
									max="10000"
									className="input-range"
									onChange={e => setRange(e.target.value)}
									style={{
										background:
											"linear-gradient(90deg, transparent, rgb(238, 218, 255) 0%, transparent 0%, transparent)",
									}}
								/>
							</label>
						</div>
					</section>
					<div className="white-bg">
						<section className="tabs main__tabs">
							<div className="tabs__container">
								<h2 className="title title_theme_dark title_size_md tabs__title">
									{data?.inscriptions?.howInstall_title ||
										"Over 12 million users trust our application"}
								</h2>
								<p className="tabs__text">
									{data?.inscriptions?.howInstall_subtitle ||
										"Follow these easy steps to install the app and start making money now"}
								</p>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 120 8"
									className="tabs__lines"
								>
									<path
										stroke="#85C249"
										strokeWidth="1.75"
										d="M.92 5.85c15.6-3.19 65.88-4.8 78.74-4.18 16.08.76-74.62 5.7-41.34 5.44C66.55 6.89 92.87 5.24 119.2 3"
									></path>
									<path
										stroke="#3A82F7"
										strokeWidth="1.75"
										d="M.92 5.85c15.6-3.19 65.88-4.8 78.74-4.18 16.08.76-74.62 5.7-41.34 5.44C66.55 6.89 92.87 5.24 119.2 3"
									></path>
								</svg>
							</div>

							<div className="tabs__wrapper">
								<ul className="list tabs__list">
									<li className="tabs__list-item">
										<button
											className={`button button_theme_light button_size_default tabs__button ${
												!!device ? "" : "button_is_active"
											}`}
											onClick={() => setDevice(false)}
											data-target-id="android"
										>
											<svg className="tabs__button-icon">
												<use xlinkHref="/win-bloggers/images/site/sprite-page.svg#android"></use>
											</svg>
											<span>Android</span>
										</button>
									</li>
									<li className="tabs__list-item">
										<button
											className={`button button_theme_light button_size_default tabs__button ${
												!!device ? "button_is_active" : ""
											}`}
											onClick={() => setDevice(true)}
											data-target-id="ios"
										>
											<svg className="tabs__button-icon">
												<use xlinkHref="/win-bloggers/images/site/sprite-page.svg#ios"></use>
											</svg>
											<span>IOS</span>
										</button>
									</li>
								</ul>
							</div>

							<div className="tabs__content">
								<div
									className="tabs__elem tabs__elem_is_active"
									data-id="android"
								>
									<div className="tabs__wrapper">
										<span
											href=""
											className="link link_size_default link_theme_default tabs__get"
										>
											{data?.inscriptions?.howInstall_button || "Get started now"}
										</span>
									</div>
									<div className="tabs__group">
										{!device &&
											data?.instructionAndroid?.map((item, index) => {
												return (
													<>
														{!!item && (
															<div
																className="phone tabs__phone phone_type_android"
																key={index}
															>
																<picture className="phone__picture">
																	<source
																		media="(min-width: 1024px)"
																		srcSet={`https://api.raulnk.com/${data?.id}/images/instructionsAndroid/${data?.currentLang}/${item}?v=${timeVersion}`}
																		type="image/avif"
																	/>
																	<source
																		media="(min-width: 768px)"
																		srcSet={`https://api.raulnk.com/${data?.id}/images/instructionsAndroid/${data?.currentLang}/${item}?v=${timeVersion}`}
																		type="image/avif"
																	/>
																	<source
																		srcSet={`https://api.raulnk.com/${data?.id}/images/instructionsAndroid/${data?.currentLang}/${item}?v=${timeVersion}`}
																		type="image/avif"
																	/>

																	<source
																		media="(min-width: 1024px)"
																		srcSet={`https://api.raulnk.com/${data?.id}/images/instructionsAndroid/${data?.currentLang}/${item}?v=${timeVersion}`}
																		type="image/webp"
																	/>
																	<source
																		media="(min-width: 768px)"
																		srcSet={`https://api.raulnk.com/${data?.id}/images/instructionsAndroid/${data?.currentLang}/${item}?v=${timeVersion}`}
																		type="image/webp"
																	/>
																	<img
																		src={`https://api.raulnk.com/${data?.id}/images/instructionsAndroid/${data?.currentLang}/${item}?v=${timeVersion}`}
																		srcSet={`https://api.raulnk.com/${data?.id}/images/instructionsAndroid/${data?.currentLang}/${item}?v=${timeVersion}`}
																		alt="Android"
																	/>
																</picture>
															</div>
														)}
													</>
												);
											})}
										{!!device &&
											data?.instructionIOS?.map((item, index) => {
												return (
													<>
														{!!item && (
															<div
																className="phone tabs__phone phone_type_android"
																key={index}
															>
																<picture className="phone__picture">
																	<source
																		media="(min-width: 1024px)"
																		srcSet={`https://api.raulnk.com/${data?.id}/images/instructionsIOS/${data?.currentLang}/${item}?v=${timeVersion}`}
																		type="image/avif"
																	/>
																	<source
																		media="(min-width: 768px)"
																		srcSet={`https://api.raulnk.com/${data?.id}/images/instructionsIOS/${data?.currentLang}/${item}?v=${timeVersion}`}
																		type="image/avif"
																	/>
																	<source
																		srcSet={`https://api.raulnk.com/${data?.id}/images/instructionsIOS/${data?.currentLang}/${item}?v=${timeVersion}`}
																		type="image/avif"
																	/>

																	<source
																		media="(min-width: 1024px)"
																		srcSet={`https://api.raulnk.com/${data?.id}/images/instructionsIOS/${data?.currentLang}/${item}?v=${timeVersion}`}
																		type="image/webp"
																	/>
																	<source
																		media="(min-width: 768px)"
																		srcSet={`https://api.raulnk.com/${data?.id}/images/instructionsIOS/${data?.currentLang}/${item}?v=${timeVersion}`}
																		type="image/webp"
																	/>
																	<img
																		src={`https://api.raulnk.com/${data?.id}/images/instructionsIOS/${data?.currentLang}/${item}?v=${timeVersion}`}
																		srcSet={`https://api.raulnk.com/${data?.id}/images/instructionsIOS/${data?.currentLang}/${item}?v=${timeVersion}`}
																		alt="Android"
																	/>
																</picture>
															</div>
														)}
													</>
												);
											})}
									</div>
								</div>
							</div>
						</section>
						<section className="advantages main__advantages">
							<div className="container">
								<ul className="list advantages__list">
									<li className="advantages-card advantages__list-item">
										<article className="advantages-card__article">
											<h3 className="title title_size_xs advantages-card__title">
												{data?.inscriptions?.howInstall_onlineSupport || "24 / 7"}
											</h3>
											<p className="advantages-card__text">
												{data?.inscriptions?.howInstall_time || "hours online support"}
											</p>
										</article>
									</li>
									<li className="advantages-card advantages__list-item">
										<article className="advantages-card__article">
											<h3 className="title title_size_xs advantages-card__title">
												{data?.inscriptions?.howInstall_multiple || "Multiple"}
											</h3>
											<p className="advantages-card__text">
												{data?.inscriptions?.howInstall_type || "sports"}
											</p>
										</article>
									</li>
									<li className="advantages-card advantages__list-item">
										<article className="advantages-card__article">
											<h3 className="title title_size_xs advantages-card__title">
												{data?.inscriptions?.howInstall_rating || "4.78 out of 5"}
											</h3>
											<p className="advantages-card__text">
												{data?.inscriptions?.howInstall_userRating || "user rating"}
											</p>
										</article>
									</li>
									<li className="advantages-card advantages__list-item">
										<article className="advantages-card__article">
											<h3 className="title title_size_xs advantages-card__title">
												{data?.inscriptions?.howInstall_bonus || "₹50,000.00"}
											</h3>
											<p className="advantages-card__text">
												{data?.inscriptions?.howInstall_welcomeBonus || "welcome bonus"}
											</p>
										</article>
									</li>
								</ul>
							</div>
						</section>
						<section
							className="reviews main__reviews swiperContainer_1"
							id="reviews"
						>
							<div className="container">
								<h2 className="title title_size_md title_theme_light reviews__title">
									{data?.inscriptions?.comments_title || "You can take our word for it"}
								</h2>
							</div>
							<Swiper
								ref={swiperRef}
								slidesPerView={3}
								spaceBetween={30}
								className="mySwiper"
							>
								{data?.comments?.map((item, index) => {
									return (
										<React.Fragment key={index}>
											<SwiperSlide>
												<div className="reviews__content">
													<picture className="reviews__picture">
														<img
															src={`https://api.raulnk.com/${data?.id}/images/users/${data?.currentLang}/${item.avatar}?v=${timeVersion}`}
															className="reviews__image"
															alt="Review"
														/>
													</picture>
												</div>
												<p className="reviews__text">
													<b style={{ fontSize: "22px", marginBottom: "10px", display: "block" }}>
														{item?.author}
													</b>
													{item?.comment}
												</p>
											</SwiperSlide>
										</React.Fragment>
									);
								})}
							</Swiper>
							<div
								className="reviews__button reviews__button-prev"
								onClick={() => {
									swiperRef.current.swiper.slidePrev();
								}}
							>
								<svg className="reviews__button-arrow">
									<use xlinkHref="/win-bloggers/images/site/sprite-page.svg#slider-arrow"></use>
								</svg>
							</div>
							<div
								className="reviews__button reviews__button-next"
								onClick={() => {
									swiperRef.current.swiper.slideNext();
								}}
							>
								<svg className="reviews__button-arrow">
									<use xlinkHref="/win-bloggers/images/site/sprite-page.svg#slider-arrow"></use>
								</svg>
							</div>
						</section>
						<section
							className="reviews main__reviews swiperContainer_2"
							id="reviews"
						>
							<div className="container">
								<h2 className="title title_size_md title_theme_light reviews__title">
									{data?.inscriptions?.comments_title || "You can take our word for it"}
								</h2>
							</div>
							<Swiper
								ref={swiperRef1}
								className="mySwiper"
							>
								{data?.comments?.map((item, index) => {
									return (
										<React.Fragment key={index}>
											<SwiperSlide>
												<div className="reviews__content">
													<picture className="reviews__picture">
														<img
															src={`https://api.raulnk.com/${data?.id}/images/users/${data?.currentLang}/${item.avatar}?v=${timeVersion}`}
															className="reviews__image"
															alt="Review"
														/>
													</picture>
												</div>
												<p className="reviews__text">
													<b style={{ fontSize: "22px", marginBottom: "10px", display: "block" }}>
														{item?.author}
													</b>
													{item?.comment}
												</p>
											</SwiperSlide>
										</React.Fragment>
									);
								})}
								<div
									className="reviews__button reviews__button-prev"
									onClick={() => {
										swiperRef1.current.swiper.slidePrev();
									}}
								>
									<svg className="reviews__button-arrow">
										<use xlinkHref="/win-bloggers/images/site/sprite-page.svg#slider-arrow"></use>
									</svg>
								</div>
								<div
									className="reviews__button reviews__button-next"
									onClick={() => {
										swiperRef1.current.swiper.slideNext();
									}}
								>
									<svg className="reviews__button-arrow">
										<use xlinkHref="/win-bloggers/images/site/sprite-page.svg#slider-arrow"></use>
									</svg>
								</div>
							</Swiper>
						</section>
						<section
							className="use-app"
							id="education"
						>
							<h2 className="use-app__title title title_size_md title_theme_light">
								{data?.inscriptions?.video_title || "Our app is ready to use"}
							</h2>
							<a
								className="button button_theme_default use-app__button"
								href="/application/apk/1win.apk"
							>
								{data?.inscriptions?.howInstall_button || "Get started now"}
							</a>

							<ul className="use-app__list list">
								{data?.videoDescription?.map((item, index) => {
									return (
										<li
											className="use-app__item"
											key={item?.avatar}
										>
											<div className="video-block">
												<picture>
													<img
														className="video-block__poster"
														src={`https://api.raulnk.com/${data?.id}/images/videoDescription/${data?.currentLang}/${item.avatar}?v=${timeVersion}`}
														alt="Button: How to register?"
													/>
												</picture>
											</div>
											<h3 className="manual__title title title_theme_light title_size_sm">
												{item?.title}
											</h3>
											<ul className="manual__list list">
												{item?.list?.map((li, i) => {
													return (
														<li
															className="manual__item"
															key={i + 1}
														>
															{li}
														</li>
													);
												})}
											</ul>
										</li>
									);
								})}
							</ul>
						</section>
					</div>
					<section className="partners">
						<div className="container">
							<div className="partners__wrapper">
								<picture className="partners__picture">
									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/1_768.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/1_768@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/1_768@3x.avif 3x"
										type="image/avif"
									/>
									<source
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/1.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/1@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/1@3x.avif 3x"
										type="image/avif"
									/>

									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/1_768.webp 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/1_768@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/1_768@3x.webp 3x"
										type="image/webp"
									/>

									<img
										className="partners__img"
										src="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/1.webp"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/1@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/1@3x.webp 3x"
										alt="ola uber logo"
									/>
								</picture>
								<picture className="partners__picture">
									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/2_768.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/2_768@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/2_768@3x.avif 3x"
										type="image/avif"
									/>
									<source
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/2.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/2@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/2@3x.avif 3x"
										type="image/avif"
									/>

									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/2_768.webp 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/2_768@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/2_768@3x.webp 3x"
										type="image/webp"
									/>

									<img
										className="partners__img"
										src="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/2.webp"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/2@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/2@3x.webp 3x"
										alt="hotstar logo"
									/>
								</picture>
								<picture className="partners__picture">
									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/3_768.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/3_768@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/3_768@3x.avif 3x"
										type="image/avif"
									/>
									<source
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/3.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/3@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/3@3x.avif 3x"
										type="image/avif"
									/>

									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/3_768.webp 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/3_768@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/3_768@3x.webp 3x"
										type="image/webp"
									/>

									<img
										className="partners__img"
										src="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/3.webp"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/3@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/3@3x.webp 3x"
										alt="icici bank logo"
									/>
								</picture>
								<picture className="partners__picture">
									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/4_768.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/4_768@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/4_768@3x.avif 3x"
										type="image/avif"
									/>
									<source
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/5.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/5@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/5@3x.avif 3x"
										type="image/avif"
									/>

									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/4_768.webp 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/4_768@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/4_768@3x.webp 3x"
										type="image/webp"
									/>

									<img
										className="partners__img"
										src="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/5.webp"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/5@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/5@3x.webp 3x"
										alt="paytm logo"
									/>
								</picture>
								<picture className="partners__picture">
									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/5_768.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/5_768@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/5_768@3x.avif 3x"
										type="image/avif"
									/>
									<source
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/4.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/4@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/4@3x.avif 3x"
										type="image/avif"
									/>

									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/5_768.webp 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/5_768@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/5_768@3x.webp 3x"
										type="image/webp"
									/>

									<img
										className="partners__img"
										src="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/4.webp"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/4@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/4@3x.webp 3x"
										alt="hungame music logo"
									/>
								</picture>
								<picture className="partners__picture">
									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/6_768.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/6_768@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/6_768@3x.avif 3x"
										type="image/avif"
									/>
									<source
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/6.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/6@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/6@3x.avif 3x"
										type="image/avif"
									/>

									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/6_768.webp 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/6_768@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/6_768@3x.webp 3x"
										type="image/webp"
									/>

									<img
										className="partners__img"
										src="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/6.webp"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/6@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/6@3x.webp 3x"
										alt="cashfree payments logo"
									/>
								</picture>
								<picture className="partners__picture partners__CCAvenue">
									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/8_768.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/8_768@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/8_768@3x.avif 3x"
										type="image/avif"
									/>
									<source
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/8.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/8@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/8@3x.avif 3x"
										type="image/avif"
									/>

									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/8_768.webp 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/8_768@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/8_768@3x.webp 3x"
										type="image/webp"
									/>

									<img
										className="partners__img"
										src="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/8.webp"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/8@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/8@3x.webp 3x"
										alt="instamojo"
									/>
								</picture>
								<picture className="partners__picture partners__instamojo">
									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/10_768.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/10_768@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/10_768@3x.avif 3x"
										type="image/avif"
									/>
									<source
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/10.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/10@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/10@3x.avif 3x"
										type="image/avif"
									/>
									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/10_768.webp 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/10_768@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/10_768@3x.webp 3x"
										type="image/webp"
									/>
									<img
										className="partners__img"
										src="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/10.webp"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/10@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/10@3x.webp 3x"
										alt="pay U logo"
									/>
								</picture>
								<picture className="partners__picture partners__payOp">
									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/7_768.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/7_768@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/7_768@3x.avif 3x"
										type="image/avif"
									/>
									<source
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/7.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/7@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/7@3x.avif 3x"
										type="image/avif"
									/>

									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/7_768.webp 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/7_768@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/7_768@3x.webp 3x"
										type="image/webp"
									/>

									<img
										className="partners__img"
										src="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/7.webp"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/7@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/7@3x.webp 3x"
										alt="cc avenue logo"
									/>
								</picture>
								<picture className="partners__picture partners__payU">
									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/9_768.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/9_768@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/9_768@3x.avif 3x"
										type="image/avif"
									/>
									<source
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/9.avif 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/9@2x.avif 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/9@3x.avif 3x"
										type="image/avif"
									/>

									<source
										media="(min-width: 768px)"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/9_768.webp 1x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/9_768@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/9_768@3x.webp 3x"
										type="image/webp"
									/>

									<img
										className="partners__img"
										src="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/9.webp"
										srcSet="https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/9@2x.webp 2x, https://cdn35998572.blazingcdn.net/projects/hin-project/img/partners/9@3x.webp 3x"
										alt="payOp logo"
									/>
								</picture>
							</div>
						</div>
					</section>
				</main>
				<footer
					className="footer"
					id="contacts"
				>
					<div className="container">
						<h2 className="footer__title title title_size_xxl title_theme_light">
							{data?.inscriptions?.footer_title}
						</h2>
						<ul className="social-media">
							<li>
								<a
									href=""
									className="social-media__link social-media__link_theme_blue"
									target="_blank"
								>
									<svg
										className="social-media__img social-media__img_theme_blue"
										aria-hidden="true"
									>
										<use xlinkHref="/win-bloggers/images/site/sprite-social.svg#telegram"></use>
									</svg>
								</a>
							</li>
							<li>
								<a
									href=""
									className="social-media__link social-media__link_theme_green"
									target="_blank"
								>
									<svg
										className="social-media__img social-media__img_theme_green"
										aria-hidden="true"
									>
										<use xlinkHref="/win-bloggers/images/site/sprite-social.svg#whatsapp"></use>
									</svg>
								</a>
							</li>
							<li>
								<a
									href=""
									className="social-media__link social-media__link_theme_orange"
									target="_blank"
								>
									<svg
										className="social-media__img social-media__img_theme_orange"
										aria-hidden="true"
									>
										<use xlinkHref="/win-bloggers/images/site/sprite-social.svg#email"></use>
									</svg>
								</a>
							</li>
						</ul>
					</div>
					<p className="copyright">© {data?.inscriptions?.patent || "2024 1win"}</p>
				</footer>
			</div>
		</>
	);
};

export default WinBloggers;
