/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import WinBloggers from "./pages/WinBloggers";
import LuckyJet from "./pages/LuckyJet";

const useDynamicStyles = href => {
	useEffect(() => {
		const link = document.createElement("link");
		link.rel = "stylesheet";
		link.href = href;
		document.head.appendChild(link);

		return () => {
			document.head.removeChild(link); // Видалити стиль при переході
		};
	}, [href]);
};

const WB = () => {
	useDynamicStyles("/styles/win-bloggers.css");
	return <WinBloggers />;
};

const LJ = () => {
	useDynamicStyles("/styles/lucky-jet.css");
	return <LuckyJet />;
};

function RootRouter() {
	return (
		<Router>
			<Routes>
				<Route
					path="/win-bloggers"
					element={<WB />}
				/>
				<Route
					path="/lucky-jet"
					element={<LJ />}
				/>
			</Routes>
		</Router>
	);
}

export default RootRouter;
